<template>
  <div class="login"></div>
</template>
<script>
import { login } from "@api/index";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "login",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const query = route.query;
    console.log(query);
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      loadingType: "spinner",
    });
    if (JSON.stringify(query) == "{}") {
      localStorage.removeItem('wxToken');
      localStorage.removeItem('wxTokenTime');
      // 跳转登录页
      router.replace("/");
    }
    login(query)
      .then((res) => {
        console.log(res.data);
        Toast.clear();
        localStorage.setItem("wxToken", res.data.token);
        localStorage.setItem(
          "wxTokenTime",
          new Date().getTime() + 59 * 1000 * 8 * 60
        );
        router.replace(query.state);
      })
      .catch(() => {
        Toast.clear();
        router.replace("/");
      });
  },
};
</script>
<style lang="less" scoped>
</style>
