<template>
  <div class="about">
    <div class="header">
      <h1 class="header-title">订阅设置</h1>
      <h2 class="header-desc">快来订阅你的专属通告吧~</h2>
    </div>
    <van-form @submit="onSubmit">
      <van-field name="cooperation" label="费用类型  (多选)">
        <template #input>
          <van-checkbox-group v-model="cooperationChecked">
            <van-checkbox
              shape="square"
              v-for="(cooperation, index) in cooperationList"
              :key="index"
              :name="index"
              >{{ cooperation }}</van-checkbox
            >
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field name="channel" label="已入驻平台(多选)">
        <template #input>
          <van-checkbox-group v-model="channelChecked">
            <van-checkbox
              v-for="(channel, index) in channelList"
              :key="index"
              :name="index"
              >{{ channel }}</van-checkbox
            >
          </van-checkbox-group>
        </template>
      </van-field>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit"
          >保存设置</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  Field,
  Form,
  Button,
  Switch,
  CheckboxGroup,
  Checkbox,
  Toast,
  Row,
  Col,
} from "vant";
import { getBaseInfo, saveRules, getRulesInfo } from "@api/index";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Home",
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [Button.name]: Button,
    [Switch.name]: Switch,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  setup() {
    const router = useRouter();
    // 渠道
    const channelList = ref([]);
    const channelChecked = ref([]);

    //合作方式
    const cooperationList = ref([]);
    const cooperationChecked = ref([]);
    getBaseInfo().then((res) => {
      channelList.value = res.data.channel;
      cooperationList.value = res.data.cooperation;
    });
    getRulesInfo().then((res) => {
      channelChecked.value = res.data.channel;
      cooperationChecked.value = res.data.cooperation;
    });

    const onSubmit = () => {
      saveRules({
        labels: [],
        fans_number: 0,
        status: "on",
        push_time: ["18:00"],
        channel: channelChecked.value,
        cooperation: cooperationChecked.value,
      })
        .then(() => {
          Toast({
            duration: 500,
            message: "保存成功",
            onClose: () => {
              router.push("/");
            },
          });
        })
        .catch((error) => {
          Toast(error.msg);
        });
    };
    return {
      channelList,
      channelChecked,
      cooperationList,
      cooperationChecked,
      onSubmit,
    };
  },
};
</script>
<style>
.van-checkbox {
  margin: 10px 0;
}
.header {
  margin-left: 32px;
  width: 690px;
  margin-top: 20px;
}
.header-title {
  margin: 10px 0 10px;
  font-size: 38px;
  font-weight: normal;
}
.header-desc {
  margin: 0 0 10px;
  color: rgba(69, 90, 100, 0.6);
  font-size: 28px;
  font-weight: normal;
}
</style>
