<template>
  <div id="Notices">
    <v-task v-for="info in taskList" :key="info.id" :info="info" />
  </div>
</template>
<script>
import { getRecordsInfo } from "@api/index";
import { ref } from "vue";
import { useRoute } from "vue-router";
import Task from "@components/Task";
export default {
  name: "Notices",
  components: {
    [Task.name]: Task,
  },
  setup() {
    const route = useRoute();
    const code = route.params.code;
    const taskList = ref([]);
    getRecordsInfo({ code: code }).then((response) => {
      let _taskList = response.data;
      _taskList.forEach((info) => {
        info.channel_icon = require(`./../assets/images/icon/${info.channel}.png`);
      });
      taskList.value = _taskList;
    });
    return {
      taskList,
    };
  },
};
</script>
<style>
#Notices {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
</style>